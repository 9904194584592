
import GSocialLinks from './goose/g-social-links';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-social-links',
  components: {
    GSocialLinks,
  },
  mixins: [cmsComponent],
  computed: {
    socialLinks() {
      return {
        youtubeUrl: this.blok.youtube_url,
        twitterUrl: this.blok.twitter_url,
        instagramUrl: this.blok.instagram_url,
        facebookUrl: this.blok.facebook_url,
        linkedinUrl: this.blok.linkedin_url,
        tiktokUrl: this.blok.tiktok_url,
      };
    },
  },
};
